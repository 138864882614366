//////////////////////////////auth actions//////////////////////////////

export const REGISTER_FIRST_STEP = "REGISTER_FIRST_STEP";
export const REGISTER_FIRST_STEP_FAIL = "REGISTER_FIRST_STEP_FAIL";
export const REGISTER_FIRST_STEP_SUCCESS = "REGISTER_FIRST_STEP_SUCCESS";

export const REGISTER_SECOND_STEP = "REGISTER_SECOND_STEP";

export const LOGIN = "LOGIN";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const CUSTOMER_STOCK = "CUSTOMER_STOCK";
export const CUSTOMER_STOCK_FAIL = "CUSTOMER_STOCK_FAIL";
export const CUSTOMER_STOCK_SUCCESS = "CUSTOMER_STOCK_SUCCESS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const SEND_MESSAGE = "SEND_MESSAGE";

export const GET_AGREEMENTS = "GET_AGREEMENTS";
export const GET_AGREEMENTS_SUCCESS = "GET_AGREEMENTS_SUCCESS";

////////////////////////////user actions//////////////////////////////////

export const GET_PHONE = "GET_PHONE";
export const GET_PHONE_SUCCESS = "GET_PHONE_SUCCESS";

export const GET_LIST_PHONE = "GET_LIST_PHONE";
export const GET_LIST_PHONE_SUCCESS = "GET_LIST_PHONE_SUCCESS";

export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";

export const GET_REGIONS = "GET_REGIONS";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";

export const PATCH_USER = "PATCH_USER";
export const PATCH_USER_FAIL = "PATCH_USER_FAIL";

export const POST_ADD_USER = "POST_ADD_USER";
export const POST_ADD_USER_FAIL = "POST_ADD_USER_FAIL";

export const DELETE_USER = "DELETE_USER";

export const RESET_USER_ERROR = "RESET_USER_ERROR";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";

export const GET_CART_COUNT = "GET_CART_COUNT";
export const GET_CART_COUNT_SUCCESS = "GET_CART_COUNT_SUCCESS";
export const GET_CART_COUNT_FAIL = "GET_CART_COUNT_FAIL";

export const GET_SEARCH_CATEGORIES = "GET_SEARCH_CATEGORIES";
export const GET_SEARCH_CATEGORIES_SUCCESS = "GET_SEARCH_CATEGORIES_SUCCESS";

export const GET_LIVE_SEARCH = "GET_LIVE_SEARCH";
export const GET_LIVE_SEARCH_SUCCESS = "GET_LIVE_SEARCH_SUCCESS";

export const GET_BANNERS = "GET_BANNERS";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";

////////////////////////////stock actions//////////////////////////////////

export const GET_STOCK = "GET_STOCK";
export const GET_STOCK_SUCCESS = "GET_STOCK_SUCCESS";

export const STOCK_SETTINGS = "STOCK_SETTINGS";
export const STOCK_SETTINGS_SUCCESS = "STOCK_SETTINGS_SUCCESS";

export const STOCK_SETTINGS_PATCH = "STOCK_SETTINGS_PATCH";
export const STOCK_SETTINGS_PATCH_FAIL = "STOCK_SETTINGS_PATCH_FAIL";
export const STOCK_SETTINGS_PATCH_SUCCESS = "STOCK_SETTINGS_PATCH_SUCCESS";

export const SEARCH_STOCK = "SEARCH_STOCK";
export const SEARCH_STOCK_SUCCESS = "SEARCH_STOCK_SUCCESS";

export const PATCH_QUANTITY = "PATCH_QUANTITY";
export const PATCH_QUANTITY_FAIL = "PATCH_QUANTITY_FAIL";

export const POST_REQUEST = "POST_REQUEST";
export const POST_REQUEST_SUCCESS = "POST_REQUEST_SUCCESS";
export const POST_REQUEST_FAIL = "POST_REQUEST_FAIL";

export const CREATE_SUB = "CREATE_SUB";

///////////////////////////////////////////////////////////////////////////

export const GET_CLINIC_LOG = "GET_CLINIC_LOG";
export const GET_CLINIC_LOG_SUCCESS = "GET_CLINIC_LOG_SUCCESS";

export const GET_CLINIC_DASH_BOARD = "GET_CLINIC_DASH_BOARD";
export const GET_CLINIC_DASH_BOARD_SUCCESS = "GET_CLINIC_DASH_BOARD_SUCCESS";

export const GET_STOCK_MANAGEMENT = "GET_STOCK_MANAGEMENT";
export const GET_STOCK_MANAGEMENT_SUCCESS = "GET_STOCK_MANAGEMENT_SUCCESS";

export const GET_PURCHASES_BY_CATEGORY = "GET_PURCHASES_BY_CATEGORY";
export const GET_PURCHASES_BY_CATEGORY_SUCCESS = "GET_PURCHASES_BY_CATEGORY_SUCCESS";

export const GET_STOCK_LEVEL = "GET_STOCK_LEVEL";
export const GET_STOCK_LEVEL_SUCCESS = "GET_STOCK_LEVEL_SUCCESS";

export const GET_MONTHLY_GRAPHIC = "GET_MONTHLY_GRAPHIC";
export const GET_MONTHLY_GRAPHIC_SUCCESS = "GET_MONTHLY_GRAPHIC_SUCCESS";

export const GET_MONTHLY_DONUT = "GET_MONTHLY_DONUT";
export const GET_MONTHLY_DONUT_SUCCESS = "GET_MONTHLY_DONUT_SUCCESS";

export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_ORDER = "GET_ACTIVITY_ORDER";
export const GET_ACTIVITY_ORDER_SUCCESS = "GET_ACTIVITY_ORDER_SUCCESS";
export const GET_ACTIVITY_ORDER_FAIL = "GET_ACTIVITY_ORDER_FAIL";

export const NEXT_STEP = "NEXT_STEP";
export const NEXT_STEP_SUCCESS = "NEXT_STEP_SUCCESS";

export const REJECT_STEP = "REJECT_STEP";
export const REJECT_STEP_SUCCESS = "REJECT_STEP_SUCCESS";

export const DELETE_ORDER_ITEM = "DELETE_ORDER_ITEM";

//////////////////////////////categories actions//////////////////////////////

export const PAGINATE = "PAGINATE";
export const PAGINATE_FAIL = "PAGINATE_FAIL";

export const GET_CAT = "GET_CAT";
export const GET_CAT_SUCCESS = "GET_CAT_SUCCESS";

export const GET_CAT_FAIL = "GET_CAT_FAIL";

export const GET_CURRENT_CAT = "GET_CURRENT_CAT";
export const GET_CURRENT_CAT_FAIL = "GET_CURRENT_CAT_FAIL";

export const POST_CAT = "POST_CAT";
export const POST_CAT_FAIL = "POST_CAT_FAIL";

export const PUT_CAT = "PUT_CAT";
export const PUT_CAT_FAIL = "PUT_CAT_FAIL";

export const DELETE_CAT = "DELETE_CAT";
export const DELETE_CAT_FAIL = "DELETE_CAT_FAIL";

export const GET_SUB_CAT = "GET_SUB_CAT";
export const GET_SUB_CAT_SUCCESS = "GET_SUB_CAT_SUCCESS";

export const GET_SUB_CAT_FAIL = "GET_SUB_CAT_FAIL";

export const POST_SUB_CAT = "POST_SUB_CAT";
export const POST_SUB_CAT_FAIL = "POST_SUB_CAT_FAIL";

export const DELETE_SUB_CAT = "DELETE_SUB_CAT";
export const DELETE_SUB_CAT_FAIL = "DELETE_SUB_CAT_FAIL";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

export const PUT_PRODUCT = "PUT_PRODUCT";
export const PUT_PRODUCT_FAIL = "PUT_PRODUCT_FAIL";

export const POST_PRODUCT = "POST_PRODUCT";
export const POST_PRODUCT_FAIL = "POST_PRODUCT_FAIL";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const CHECK_STOCKS = "CHECK_STOCKS";
export const CHECK_STOCKS_FAIL = "CHECK_STOCKS_FAIL";

export const GET_PRODS_FOR_STOCKS = "GET_PRODS_FOR_STOCKS";
export const GET_PRODS_FOR_STOCKS_FAIL = "GET_PRODS_FOR_STOCKS_FAIL";

export const CREATE_INVENTORY = "CREATE_INVENTORY";
export const CREATE_INVENTORY_FAIL = "CREATE_INVENTORY_FAIL";

export const PATCH_INVENTORY = "PATCH_INVENTORY";
export const PATCH_INVENTORY_FAIL = "PATCH_INVENTORY_FAIL";
export const PATCH_CART_REQUEST = "PATCH_CART_REQUEST";

export const PATCH_PURCHASE_ORDER_FILE = "PATCH_PURCHASE_ORDER_FILE";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const GET_MAIN_CATALOG = "GET_MAIN_CATALOG";
export const GET_MAIN_CATALOG_SUCCESS = "GET_MAIN_CATALOG_SUCCESS";

export const GET_PROMOTIONALS = "GET_PROMOTIONALS";
export const GET_PROMOTIONALS_SUCCESS = "GET_PROMOTIONALS_SUCCESS";

export const GET_SEARCH_RESULTS_CATEGORIES = "GET_SEARCH_RESULTS_CATEGORIES";
export const GET_SEARCH_RESULTS_CATEGORIES_SUCCESS = "GET_SEARCH_RESULTS_CATEGORIES_SUCCESS";

export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";

export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";

export const GET_CATEGORY_RESULTS = "GET_CATEGORY_RESULTS";
export const GET_CATEGORY_RESULTS_SUCCESS = "GET_CATEGORY_RESULTS_SUCCESS";

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";

export const GET_RECOMMENDED = "GET_RECOMMENDED";
export const GET_RECOMMENDED_SUCCESS = "GET_RECOMMENDED_SUCCESS";

export const GET_SUBS = "GET_SUBS";
export const GET_SUBS_SUCCESS = "GET_SUBS_SUCCESS";

export const CANCEL_SUB = "CANCEL_SUB";
